<template>
    <div class="container">
      <div class="head">
        <HeaderView :bgColor="bgColor"></HeaderView>
      </div>
      <div class="banner">
        <div class="banner_text">
          <h1>WMS仓储管理系统</h1>
          <p>无缝对接物流平台，实现订单及库存可视化管理</p>
        </div>
      </div>
      <div class="main">
        <div class="main_title wow animate__fadeInUp" data-wow-duration="2s">平台介绍</div>
        <div class="main_box wow animate__fadeInUp" data-wow-duration="2s">
            <div class="main_left">
                <h1><img src="../assets/images/storage/ic.png" alt=""><span>WMS仓储管理系统</span></h1>
                <p>仓储系统是为各种企业打造的智能化仓储管理系统，配合科学的仓库规划，提升企业在 后端仓储供应链上的竞争力为企业提供了优化解决方案，为企业后续建设自动化仓储 管理打下了坚实基础。</p>
            </div>
            <div class="main_right">
                <img src="../assets/images/storage/wms.png" alt="">
            </div>
        </div>
      </div>
      <div class="main1">
        <div class="main_title wow animate__fadeInUp" data-wow-duration="2s">主要系统功能</div>
        <div class="main1_box wow animate__fadeInUp" data-wow-duration="2s">
            <p>
                WMS系统不仅具备仓储管理系统的出入库业务、库位库存、仓内作业管理等常规功能，还增加了仓库监控管理、报表管理、数据分析等功能，通 过全产业链配套管理，使用户低成本实现信息化和数字化升级。
            </p>
            <img src="../assets/images/storage/pic.png" alt="">
        </div>
      </div>
      <div class="main2">
        <div class="main_title wow animate__fadeInUp" data-wow-duration="2s">系统优势</div>
        <ul class=" wow animate__fadeInUp" data-wow-duration="2s">
            <li v-for="(item,index) in ulData" :key="index">
                <h3>{{item.title}}</h3>
                <p>{{item.con}}</p>
                <img :src="item.url" alt="">
            </li>
        </ul>
      </div>
      <div class="main3">
        <div class="main_title wow animate__fadeInUp" data-wow-duration="2s">功能架构</div>
        <img class=" wow animate__fadeInUp" data-wow-duration="2s" src="../assets/images/storage/gnjg.png" alt="">
      </div>
    </div>
  </template>
  
  <script>
  import HeaderView from '@/components/headerView.vue'
  export default {
    name: 'Storage',
    data(){
      return{
        bgColor:'',
        ulData:[
             {url:require('../assets/images/storage/ic_cangchu.png'),title:'高效仓储管理',con:'系统通过科学优化作业流程、让库存实时同步、过程实时 监控和可追溯，确保仓库作业员工更省时、更省力、准确 率更高，让基层管理者更方便查询与管理。'},
             {url:require('../assets/images/storage/ic_fenxi.png'),title:'整合分析数据',con:'依托强大的数据深度分析功能，将大量的仓库数据进行整 合，再结合上游采购数据和下游销售数据，为企业决策提 供强有力的数据支撑。'},
             {url:require('../assets/images/storage/ic_zhineng.png'),title:'智能化规范化',con:'WMS系统的运行集成化、业务流程合理化、绩效监控 动态化、管理改善持续化，让仓库管理在实现规范化、信息 化的同时，更是实现了高度数字化和智能化。'},
             {url:require('../assets/images/storage/ic_rongzi.png'),title:'缓解融资困境',con:'用户通过使用平台产生各种大量的交易数据，通过大数据 系统的汇总采集及其金融系统风控分析，为平台用户提供 有效的数据支撑，从而解决企业融资问题。'}
        ]
      }
    },
    components: {
      HeaderView
    },
    created(){
    },
    mounted(){
      new this.$wow.WOW().init({//新建实列
          boxClass: 'wow',//class名字
          // animateClass: 'animate__animated',//animateclass动画库类名,和当前animate动画库决定
          offset: 0,//到元素距离触发动画（当默认为0）
          mobile: true,//在移动设备上触发动画（默认为true）
          live: true//对异步加载的内容进行操作（默认为true）
        })
      },
    methods:{
      
    }
  }
  </script>
  <style scoped lang="less">
  .container{
    // 出现滚动条时，背景图铺完
    min-width: max-content;
    min-width: -moz-max-content;
    .banner{
      width:1920px;
      height:700px;
      position: relative;
      background: url('../assets/images/storage/banner.jpg')no-repeat;
      background-size:100% 100%;
      .banner_text{
        width:1900px;
        margin:0 auto;
        padding-top:310px;
        padding-left:200px;
        text-align:left;
        color: #FEFEFE;
        box-sizing: border-box;
        animation: banner-animation 1s ease-in-out;
      }
      h1{
        height: 40px;
        font-size: 40px;
        margin-bottom:10px;
        font-family: SourceHanSansSC-Medium, SourceHanSansSC;
        font-weight: 500;
      }
      p{
        height:72px;
        line-height:72px;
        font-size: 30px;
      }
    }
    .main_title{
        font-size: 34px;
        font-family: SourceHanSansSC-Medium, SourceHanSansSC;
        font-weight: 500;
        color: #333333;
        text-align:center;
    }
    .main{
        width: 1920px;
        height: 513px;
        background: #F9F9F9;
        opacity: 1;
        padding-top:85px;
        box-sizing: border-box;
        .main_box{
            width:1200px;
            margin:0 auto;
            padding-top:50px;
            display:flex;
            justify-content: space-between;
            .main_left{
                width:520px;
                padding-top:10px;
                h1{
                    display:flex;
                    align-items:center;
                    span{
                        color:#333333;
                        font-size:24px;
                        padding-left:12px;
                    }
                }
                p{
                    font-size: 14px;
                    font-family: SourceHanSansSC-Medium, SourceHanSansSC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 26px;
                    margin-top:30px;
                }
            }
        }
    }
    .main1{
        width:1920px;
        height:785px;
        background:#ffffff;
        box-sizing: border-box;
        padding-top:83px;
        .main1_box{
            width:1200px;
            padding-top:43px;
            margin:0 auto;
            text-align:center;
            p{
                width:844px;
                margin:0 auto 80px;
                font-size: 14px;
                font-family: SourceHanSansSC-Medium, SourceHanSansSC;
                font-weight: 500;
                color: #333333;
                line-height: 24px;
            }
        }
    }
    .main2{
        width: 1920px;
        height: 623px;
        background: #F9F9F9;
        opacity: 1;
        padding-top:60px;
        box-sizing: border-box;
        ul{
            width:1200px;
            margin:0 auto;
            margin-top:50px;
            display:flex;
            justify-content: space-between;
            flex-wrap:wrap;
            li{
                width: 593px;
                height: 183px;
                background: #FFFFFF;
                box-sizing: border-box;
                margin-bottom:15px;
                padding:40px 74px 40px 170px;
                position:relative;
                h3{
                    color:#333333;
                    font-size:18px;
                    margin-bottom:16px;
                }
                p{
                    color:#333333;
                    font-size:14px;
                    line-height:24px;
                }
                img{
                    position:absolute;
                    top:60px;
                    left:62px;
                }
            }
        }
    }
    .main3{
        width:1920px;
        height:832px;
        background:#ffffff;
        padding-top:70px;
        img{
            display:block;
            margin:60px auto 0;
        }
    }
  }
  </style>
  