<template>
    <div class="container">
      <div class="head">
        <HeaderView :bgColor="bgColor"></HeaderView>
      </div>
      <div class="banner">
        <div class="banner_text">
          <h1>钢铁加工管理系统</h1>
          <p>立即开启</p>
        </div>
      </div>
      <div class="main">
        <div class="main_title wow animate__fadeInUp" data-wow-duration="2s">钢铁加工管理系统<span class="line"></span></div>
        <div class="main_box">
          <p class=" wow animate__fadeInUp" data-wow-duration="2s">
            钢铁加工管理系统是针对钢材制品的加工企业或加工厂的作业管理，以细分行业加工的工艺路线为基础，以成本控制为中心，通过有效的项目管理和作业调度实现有序高效生产。其中包括任务设置、生产排工、领料优化、加工优化、工程项目管理、成本核算等短流程加工制造企业的全面解决方案。
          </p>
          <img class=" wow animate__fadeInUp" data-wow-duration="2s" src="../assets/images/process/pic.png" alt="">
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import HeaderView from '@/components/headerView.vue'
  export default {
    name: 'Process',
    data(){
      return{
        bgColor:'',
      }
    },
    components: {
      HeaderView
    },
    created(){
    },
    mounted(){
      new this.$wow.WOW().init({//新建实列
        boxClass: 'wow',//class名字
        // animateClass: 'animate__animated',//animateclass动画库类名,和当前animate动画库决定
        offset: 0,//到元素距离触发动画（当默认为0）
        mobile: true,//在移动设备上触发动画（默认为true）
        live: true//对异步加载的内容进行操作（默认为true）
      })
    },
    methods:{
      
    }
  }
  </script>
  <style scoped lang="less">
  .container{
    // 出现滚动条时，背景图铺完
    min-width: max-content;
    min-width: -moz-max-content;
    .banner{
      width:1920px;
      height:700px;
      position: relative;
      background: url('../assets/images/process/banner.jpg')no-repeat;
      background-size:100% 100%;
      .banner_text{
        width:1900px;
        margin:0 auto;
        padding-top:250px;
        text-align:center;
        color: #FEFEFE;
        box-sizing: border-box;
        animation: banner-animation 1s ease-in-out;
      }
      h1{
        height: 40px;
        font-size: 60px;
        margin-bottom:80px;
        font-family: SourceHanSansSC-Medium, SourceHanSansSC;
        font-weight: 500;
      }
      p{
        width: 360px;
        height: 60px;
        line-height:60px;
        background: #0057FF;
        border-radius: 41px 41px 41px 41px;
        opacity: 1;
        font-size: 28px;
        font-family: SourceHanSansSC-Bold, SourceHanSansSC;
        font-weight: bold;
        color: #FFFFFF;
        margin:0 auto;
      }
    }
    .main_title{
        font-size: 34px;
        font-family: SourceHanSansSC-Medium, SourceHanSansSC;
        font-weight: 500;
        color: #333333;
        text-align:center;
        position:relative;
        .line{
          display:block;
          width: 61px;
          height: 5px;
          background: #0075DF;
          opacity: 1;
          position:absolute;
          left:48.4%;
          top:65px;
        }
    }
    .main{
        width: 1920px;
        height: 1178px;
        background: #ffffff;
        opacity: 1;
        padding-top:120px;
        box-sizing: border-box;
        .main_box{
          width:1200px;
          margin:0 auto;
          text-align:center;
          p{
            width:943px;
            margin:60px auto 90px;
            text-align:center;
            font-size:16px;color:#333333;
            line-height:28px;
          }
        }
    }
  }
  </style>
  