<template>
  <div id="test">
    <baidu-map class="bm-view" @ready="init">
      <div v-for="item in maps" :key="item.id">
        <!-- 打点，遍历maps数组-->
        <bm-marker :position="{ lng: item.lng, lat: item.lat }" @click="lookDetail(item)">
          <!-- <bm-label content="淘钢网"></bm-label> -->
          <!-- 标注，遍历出maps数组的每一项-->
          <!-- <bm-label :content="item.deptname + item.zbmc" :labelStyle="{ color: 'red', fontSize: '12px' }"
            :offset="{ width: -35, height: 30 }" @click="lookDetail(item)" /> -->
          <!-- 窗口 -->
          <bm-info-window :title="showTitle" :positon="{ lng: infoWindow.lng, lat: infoWindow.lat }"
            :offset="{ width: 0, height: 0 }" :show="item.showFlag" @close="infoWindowClose(item)"
            @open="infoWindowOpen(item)">
            <div class="info-window">
              河南省郑东新区电子商务大厦B座14-15层
            </div>

          </bm-info-window>
        </bm-marker>
        <!-- 信息窗口 -->

      </div>
    </baidu-map>

  </div>
</template>
 
<script>
export default {
  data() {
    return {
      map: "", // 地图
      // 站点信息数组
      maps: [
        {
          lng: '113.816072', lat: '34.746746',
          showFlag: true
        }
      ],
      showTitle: "淘钢网",
      infoWindow: {
        lng: "113.816072",
        lat: "34.746746",
      },
    }
  },
  methods: {
    //地图方法
    init({ BMap, map }) {
      // this.deptGisList()
      let point = new BMap.Point(113.816072, 34.746746); //设置城市经纬度坐标
      map.centerAndZoom(point, 20); //地图缩放比例
      map.setCurrentCity("郑州"); // 设置地图显示的城市 此项是必须设置的
      map.enableScrollWheelZoom(true); //鼠标滚动缩放
    },
    // 点击弹框
    lookDetail(item) {
      this.infoWindow.lng = 113.816072;
      this.infoWindow.lat = 34.746746;
      item.showFlag = true; //showFlag来控制弹框展示和隐藏
      console.log(this.infoWindow);
    },
    infoWindowClose(item) {
      item.showFlag = false

    },
    infoWindowOpen(item) {
      item.showFlag = true
    }

  }
}
</script>
<style>
.bm-view {
  width: 100%;
  height: 100vh;
}
</style>
 