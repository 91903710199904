<template>
    <div>
        <div class="head">
            <HeaderView :bgColor="bgColor"></HeaderView>
          </div>
        <div class="banner">
            <div class="banner_text">
                <h1>联系我们</h1>
            </div>
        </div>
        <div class="contact_box">
            <div class="module_title wow animate__fadeInUp" data-wow-duration="2s">
                联系我们
                <div class="line"></div>
            </div>
            <ul class="contact_content wow animate__fadeInUp" data-wow-duration="2s">
                <li>
                    <div class="contact_icon">
                      <img src="../assets/images/contact/address.png" alt=""/> 
                    </div>
                    <h4>ADDRESS</h4>
                    <img class='line' src="../assets/images/contact/line.png" alt="">
                    <p v-if="companyInfo.length > 0">{{companyInfo[0].value}}</p>
                    <!-- <p>14层-15层</p> -->
                </li>
                <li>
                    <div class="contact_icon">
                        <img src="../assets/images/contact/email.png" alt=""/>
                    </div>
                    <h4>E-MAIL</h4>
                    <img class='line' src="../assets/images/contact/line.png" alt="">
                    <p v-if="companyInfo.length > 0">业务合作：{{companyInfo[1].value}}</p>
                </li>
                <li>
                    <div class="contact_icon">
                        <img src="../assets/images/contact/tell.png" alt=""/>
                    </div>
                    <h4>TELEPHONE</h4>
                    <img class='line' src="../assets/images/contact/line.png" alt="">
                    <p v-if="companyInfo.length > 0">{{companyInfo[2].value}}</p>
                </li>
            </ul>
        </div>
        <div class="map_box wow animate__fadeInUp" data-wow-duration="2s">
            <div>
                <Map></Map>
            </div>
        </div>
    </div>
</template>
<script>
import { getContact } from '@/request/api'
import Map from '@/components/map.vue'
import HeaderView from '@/components/headerView.vue'
export default {
    name:'companyProfile',
    components:{ Map, HeaderView },
    data(){
        return{
            // bgColor:'rgba(235,229,225,.8)',
            bgColor:'',
            companyInfo:[],
        }
    },
    mounted(){
        this.getContact()
        new this.$wow.WOW().init({//新建实列
            boxClass: 'wow',//class名字
            // animateClass: 'animate__animated',//animateclass动画库类名,和当前animate动画库决定
            offset: 0,//到元素距离触发动画（当默认为0）
            mobile: true,//在移动设备上触发动画（默认为true）
            live: true//对异步加载的内容进行操作（默认为true）
        })
    },
    methods:{
        getContact(){
            getContact().then((res)=>{
                this.companyInfo = res.data
            })
        }
    }
}
</script>
<style scoped lang="less">
.banner{
    width:1920px;
    height:471px;
    position: relative;
    background: url('../assets/images/contact/banner.jpg')no-repeat;
    background-size:100% 100%;
    .banner_text{
      width:1200px;
      margin:0 auto;
      padding-top:300px;
      padding-left:80px;
      text-align:left;
      color: #000000;
      animation: banner-animation 1s ease-in-out;
    }
    h1{
        font-size: 40px;
        text-align:center;
    }
}
.contact_box{
    width:1920px;
    background:#F2F2F2;
    padding:30px 0;
    margin-bottom:50px;
    .contact_content{
        width:1200px;
        margin:0 auto;
        display:flex;
        justify-content: space-around;
        li{
            text-align:center;
            width:388px;
            height:322px;
            padding:50px;
            box-sizing:border-box;
            background:#fff;
            .contact_icon{
                height:58px;
                margin-bottom:25px;
            }
            h4{
                font-size:20px;
            }
            .line{margin:20px 0;}
            p{
                line-height:28px;
                font-size: 16px;
                color: #656565;
            }
        }
    }
}
.map_box{
    width:1920px;
    height:600px;
    margin:0 auto 100px;
    overflow:hidden;
    div{
        width:1200px;
        margin:0 auto;
    }
}
</style>