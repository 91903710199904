<template>
    <div class="container">
      <div class="head">
        <HeaderView :bgColor="bgColor"></HeaderView>
      </div>
      <div class="banner">
        <div class="banner_text">
          <h1>数字赋能，让货运更高效</h1>
          <p>立即开启</p>
        </div>
      </div>
      <div class="main">
        <div class="main_box wow animate__fadeInUp" data-wow-duration="2s">
            <img src="../assets/images/logistics/pic_one.jpg" alt="">
            <dl>
                <dt>运动调度数字化</dt>
                <dd v-for="(item,index) in ddlist" :key="index">
                    <img :src="item.url" alt="">
                    <p>{{item.title}}</p>
                    <p>{{item.con}}</p>
                </dd>
            </dl>
        </div>
      </div>
      <div class="main1">
        <div class="main1_box wow animate__fadeInUp" data-wow-duration="2s">
            <div class="main1_box_left">
                <div class="main1_box_title">在途管理数字化</div>
                <p>基于海量行驶大数据和算法，预判车辆到达指定节点的时间、距离，上报车辆 位置信息，对车辆路途管控、货物送达时间管理、路途风险预警等进行数字化 升级，降低运输风险。</p>
                <ul>
                    <li v-for="(item,index) in liData" :key="index">{{item}}</li>
                </ul>
            </div>
            <img src="../assets/images/logistics/pic.jpg" alt="">
        </div>
      </div>
      <div class="main2">
        <div class="main2_box wow animate__fadeInUp" data-wow-duration="2s">
            <img src="../assets/images/logistics/pic.png" alt="">
            <div class="main2_box_right">
                <div class="main2_box_title">
                    运费结算数字化
                </div>
                <p>实现从运单发布到报销开票，从运费、油费到人工、货损的纵横式财务数字化 管理，减少人工流程，提高结算效率和准确率。</p>
                <ul>
                    <li>运输费用在线管理</li>
                    <li>财务线上审核、轻松支付</li>
                    <li>货主司机线上对账，结算进度一目了然</li>
                </ul>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import HeaderView from '@/components/headerView.vue'
  export default {
    name: 'Logistics',
    data(){
      return{
        bgColor:'',
        ddlist:[
            {url:require('../assets/images/logistics/ic.png'),title:'货主',con:"一键发布运输计划、经营数据清晰可控、运力一站式管理"},
            {url:require('../assets/images/logistics/ic_one.png'),title:'调度员',con:"派单模式灵活选择、运单实时监控、异常运单后台提醒"},
            {url:require('../assets/images/logistics/ic_two.png'),title:'司机',con:"在线接单、实时交互、运费线上结算"},
        ],
        liData:['实时监控','异常预警','定位跟踪','远程协同','在途调拨','货损控制']
      }
    },
    components: {
      HeaderView
    },
    created(){
    },
    mounted(){
      new this.$wow.WOW().init({//新建实列
        boxClass: 'wow',//class名字
        // animateClass: 'animate__animated',//animateclass动画库类名,和当前animate动画库决定
        offset: 0,//到元素距离触发动画（当默认为0）
        mobile: true,//在移动设备上触发动画（默认为true）
        live: true//对异步加载的内容进行操作（默认为true）
      })
    },
    methods:{
      
    }
  }
  </script>
  <style scoped lang="less">
  .container{
    // 出现滚动条时，背景图铺完
    min-width: max-content;
    min-width: -moz-max-content;
    .banner{
      width:1920px;
      height:700px;
      position: relative;
      background: url('../assets/images/logistics/banner.jpg')no-repeat;
      background-size:100% 100%;
      .banner_text{
        width:1900px;
        margin:0 auto;
        padding-top:300px;
        text-align:left;
        color: #ffffff;
        animation: banner-animation 1s ease-in-out;
      }
      h1{
        text-align:center;
        height: 40px;
        font-size: 40px;
        margin-bottom:20px;
      }
      p{
        width:360px;
        height:72px;
        line-height:72px;
        background:#0057FF;
        border-radius:36px;
        font-size: 30px;
        text-align:center;
        margin:0 auto;
        margin-top:50px;
      }
    }
  }
  .main{
    width:1920px;
    height:559px;
    box-sizing: border-box;
    padding-top:98px;
    padding-bottom:136px;
    .main_box{
        width:1200px;
        margin:0 auto;
        display:flex;
        dl{
            padding-left:130px;
            color:#333333;
            font-family: SourceHanSansSC-Medium, SourceHanSansSC;
            box-sizing: border-box;
            dt{
                font-size:32px;
                margin:25px 0;
            }
            dd{
                font-size:14px;
                position:relative;
                margin:23px 0;
                margin-left:45px;
                p{
                    &:nth-of-type(1){
                        font-size:16px;
                        margin-bottom:10px;
                    }
                }
                img{
                    position:absolute;
                    left:-45px;;
                    top:0;
                }
            }
        }
    }
  }
  .main1{
    width:1920px;
    height:664px;
    padding:100px 0 200px 0;
    background:#EFF7FD;
    box-sizing: border-box;
    .main1_box{
        width:1200px;
        margin:0 auto;
        display:flex;
        justify-content: space-between;
        .main1_box_left{
            .main1_box_title{
                height: 31px;
                font-size: 32px;
                font-family: SourceHanSansSC-Bold, SourceHanSansSC;
                font-weight: bold;
                color: #333333;
                margin-bottom:70px;
            }
            p{
                width: 455px;
                height: 62px;
                font-size: 14px;
                font-family: SourceHanSansSC-Medium, SourceHanSansSC;
                font-weight: 500;
                color: #333333;
                line-height: 24px; 
                margin-bottom:50px;
            }
            ul{
                width:400px;
                height:100px;
                display:flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: space-between;
                li{
                    width: 126px;
                    height: 43px;
                    line-height: 43px;
                    background: #FFFFFF;
                    opacity: 1;
                    border: 1px solid #DADADA;
                    font-size:14px;
                    color:#333333;
                    text-align:center;
                    box-sizing: border-box;
                }
            }

        }
        img{
            position:relative;
            top:15px;
        }
    }
  }
  .main2{
    width:1920px;
    height:630px;
    padding:117px 0 187px 0;
    background:#ffffff;
    box-sizing: border-box;
    .main2_box{
        width:1200px;
        margin:0 auto;
        display:flex;
        justify-content: space-between;
        .main2_box_right{
            .main2_box_title{
                height: 32px;
                font-size: 32px;
                font-family: SourceHanSansSC-Bold, SourceHanSansSC;
                font-weight: bold;
                color: #333333;
                margin-bottom:30px;
                margin-top:50px;
            }
            p{
                width: 455px;
                font-size: 14px;
                font-family: SourceHanSansSC-Medium, SourceHanSansSC;
                color: #333333;
                line-height: 28px; 
                margin-bottom:34px;
                font-weight: 500;
            }
            ul{
                margin-left:20px;
                li{
                    height: 28px;
                    font-size: 14px;
                    font-family: SourceHanSansSC-Medium, SourceHanSansSC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 28px;
                    list-style-type: disc;
                    &::marker {
                        color:#1181E1;
                    }
                }
            }
        }
    }
  }
  </style>
  