<template>
    <div class="container">
      <div class="head">
        <HeaderView :bgColor="bgColor"></HeaderView>
      </div>
      <div class="banner">
        <div class="banner_text">
          <h1>金融服务系统</h1>
          <p>降低企业融资成本，让融资服务更便利</p>
        </div>
      </div>
      <div class="main">
        <div class="main_title wow animate__fadeInUp" data-wow-duration="2s">平台介绍</div>
        <div class="main_box wow animate__fadeInUp" data-wow-duration="2s">
            <div class="main_left">
                <h1><img src="../assets/images/finance/ic.png" alt=""><span>金融服务系统</span></h1>
                <p>平台通过与各类金融机构的合作，为各平台及系统参与方提供仓单
                  质押、库存质押、应收账款融资、订单融资等供应链金融服务，解决企业
                  融资难、融资贵等问题。通过技术手段实现信息流、商流、资金流、物流
                  “四流合一”，进而提升金融机构对风险的把控能力，为中小企业提供全
                  方位一站式融资服务。</p>
            </div>
            <div class="main_right">
                <img src="../assets/images/finance/pic.png" alt="">
            </div>
        </div>
      </div>
      <div class="main1">
        <div class="main_title wow animate__fadeInUp" data-wow-duration="2s">融资方式</div>
        <ul class=" wow animate__fadeInUp" data-wow-duration="2s">
          <li v-for="(item,index) in wayData" :key="index">
            <h3>{{item.title}}</h3>
            <p>{{item.con}}</p>
            <img :src="item.url" alt="">
          </li>
        </ul>
      </div>
      <div class="main2">
        <div class="main_title wow animate__fadeInUp" data-wow-duration="2s">平台优势</div>
        <ul class=" wow animate__fadeInUp" data-wow-duration="2s">
            <li v-for="(item,index) in ulData" :key="index">
                <img :src="item.url" alt="">
                <h3>{{item.title}}</h3>
                <p v-for="(Iitem,Iindex) in item.con" :key="Iindex">{{Iitem}}</p>
            </li>
        </ul>
      </div>
      <div class="main3">
        <div class="main_title wow animate__fadeInUp" data-wow-duration="2s">功能架构</div>
        <img class=" wow animate__fadeInUp" data-wow-duration="2s" src="../assets/images/finance/gnjg.png" alt="">
      </div>
    </div>
  </template>
  
  <script>
  import HeaderView from '@/components/headerView.vue'
  export default {
    name: 'Finance',
    data(){
      return{
        bgColor:'',
        ulData:[
             {url:require('../assets/images/finance/ic_wuzhi.png'),title:'无纸化融资',con:['全线上无纸化融资流程，','降低企业融资隐性成本，提升融资效率。']},
             {url:require('../assets/images/finance/ic_rongzi.png'),title:'融资更安全',con:['融资企业、金融机构通过供应链金融平台','能够实现实时查看融资进度及业务各节点','单据凭证信息，融资更加安全透明。']},
             {url:require('../assets/images/finance/ic_kuaisu.png'),title:'快速促活',con:['为合作客户的产业链注入新鲜','血液，在周期内提高企业的贸','易效率和盈利水平。']},
             {url:require('../assets/images/finance/ic_panhuo.png'),title:'盘活资金',con:['盘活企业资金占用，增加','企业现金流，扩大企业经营、业务规模。']},
             {url:require('../assets/images/finance/ic_jiankong.png'),title:'严格监控',con:['平台对交易、资金、信息实时','把控，保证金融机构与合作客户的合作权益。']},
             {url:require('../assets/images/finance/ic_shenhe.png'),title:'双重审核',con:['授信双重审核，','业务双重监管，预警可视化。']}
        ],
        wayData:[
          {url:require('../assets/images/finance/ic_unhuo.png'),title:'存货融资',con:'存货质押融资是指需要融资的企业，将其拥有的存货做质物，向 金融机构出质，由蜘点产业平台对应的监管仓进行存货保管和 控制，以获得金融机构贷款的业务活动。'},
             {url:require('../assets/images/finance/ic_dingdan.png'),title:'订单融资',con:'融资方持金融机构认可的产品订单以及与交易方签订的真实 有效的商务合同，并以该商务合同作为回笼货款第一还贷来 源，向金融机构申请融资。'},
        ]
      }
    },
    components: {
      HeaderView
    },
    created(){
    },
    mounted(){
      new this.$wow.WOW().init({//新建实列
        boxClass: 'wow',//class名字
        // animateClass: 'animate__animated',//animateclass动画库类名,和当前animate动画库决定
        offset: 0,//到元素距离触发动画（当默认为0）
        mobile: true,//在移动设备上触发动画（默认为true）
        live: true//对异步加载的内容进行操作（默认为true）
      })
    },
    methods:{
      
    }
  }
  </script>
  <style scoped lang="less">
  .container{
    // 出现滚动条时，背景图铺完
    min-width: max-content;
    min-width: -moz-max-content;
    .banner{
      width:1920px;
      height:700px;
      position: relative;
      background: url('../assets/images/finance/banner.png')no-repeat;
      background-size:100% 100%;
      .banner_text{
        width:1900px;
        margin:0 auto;
        padding-top:310px;
        padding-left:200px;
        text-align:left;
        color: #FEFEFE;
        box-sizing: border-box;
        animation: banner-animation 1s ease-in-out;
      }
      h1{
        height: 40px;
        font-size: 40px;
        margin-bottom:10px;
        font-family: SourceHanSansSC-Medium, SourceHanSansSC;
        font-weight: 500;
      }
      p{
        height:72px;
        line-height:72px;
        font-size: 30px;
      }
    }
    .main_title{
        font-size: 34px;
        font-family: SourceHanSansSC-Medium, SourceHanSansSC;
        font-weight: 500;
        color: #333333;
        text-align:center;
    }
    .main{
        width: 1920px;
        height: 513px;
        background: #ffffff;
        opacity: 1;
        padding-top:85px;
        box-sizing: border-box;
        .main_box{
            width:1200px;
            margin:0 auto;
            padding-top:50px;
            display:flex;
            justify-content: space-between;
            .main_left{
                width:520px;
                padding-top:10px;
                h1{
                    display:flex;
                    align-items:center;
                    span{
                        color:#333333;
                        font-size:24px;
                        padding-left:12px;
                    }
                }
                p{
                    font-size: 14px;
                    font-family: SourceHanSansSC-Medium, SourceHanSansSC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 26px;
                    margin-top:30px;
                }
            }
        }
    }
    .main1{
        width:1920px;
        height:444px;
        background:url('../assets/images/finance/bg.jpg')no-repeat;
        background-size:100% 100%;
        box-sizing: border-box;
        padding-top:53px;
        ul{
          width:1200px;
          margin:0 auto;
          margin-top:50px;
          display:flex;
          justify-content: space-between;
          flex-wrap:wrap;
          li{
              width: 590px;
              height: 183px;
              background: #FFFFFF;
              box-sizing: border-box;
              margin-bottom:15px;
              padding:40px 64px 0 143px;
              position:relative;
              h3{
                  color:#0075D7;
                  font-size:18px;
                  margin-bottom:13px;
              }
              p{
                  color:#333333;
                  font-size:14px;
                  line-height:24px;
              }
              img{
                  position:absolute;
                  top:60px;
                  left:42px;
              }
          }
      }
    }
    .main2{
        width: 1920px;
        height: 802px;
        background: url('../assets/images/finance/bg.png')no-repeat;
        opacity: 1;
        padding-top:53px;
        box-sizing: border-box;
        ul{
            width:1200px;
            margin:0 auto;
            margin-top:50px;
            display:flex;
            justify-content: space-between;
            flex-wrap:wrap;
            li{
                width: 350px;
                background: #FFFFFF;
                box-sizing: border-box;
                margin-bottom:60px;
                text-align:center;
                img{
                  margin-bottom:33px;
                }
                h3{
                    color:#000000;
                    font-size:18px;
                    margin-bottom:10px;
                    font-weight:bold;
                }
                p{
                  font-size: 16px;
                  font-family: SourceHanSansSC-Regular, SourceHanSansSC;
                  font-weight: 400;
                  color: #656565;
                  line-height:28px;
                }
            }
        }
    }
    .main3{
      width: 1920px;
      height: 852px;
      background: #F9F9F9;
        padding-top:53px;
        img{
            display:block;
            margin:35px auto 0;
        }
    }
  }
  </style>
  