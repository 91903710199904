<template>
    <div class="container">
      <div class="head">
        <HeaderView :bgColor="bgColor"></HeaderView>
      </div>
      <div class="banner">
        <div class="banner_text">
          <h1>钢铁数字化工业智慧园区</h1>
          <p>钢铁行业专业数字化升级方案</p>
          <div class="btn">立即开启</div>
        </div>
      </div>
      <div class="main">
        <div class="main_title wow animate__fadeInUp" data-wow-duration="2s">平台介绍</div>
        <div class="main_box wow animate__fadeInUp" data-wow-duration="2s">
            <div class="main_left">
                <h1><img src="../assets/images/park/ic.png" alt=""><span>钢铁数字化工业智慧园区</span></h1>
                <p>针对传统钢铁物流园区的升级方案，提升传统物流园区数字化管理能力，智慧 园区管理平台为园区运营管理提供系统化，定制化的园区租赁，物业，水电，资 产，财务，商务服务等一体化管理与服务软件系统，实现园区业务数字化，线上 化的高效管理，降本增效。</p>
            </div>
            <div class="main_right">
                <img src="../assets/images/park/pic.jpg" alt="">
            </div>
        </div>
      </div>
      <div class="main1">
        <div class="main_title wow animate__fadeInUp" data-wow-duration="2s">系统优势</div>
        <ul class=" wow animate__fadeInUp" data-wow-duration="2s">
            <li v-for="(item,index) in ulData" :key="index">
                <img :src="item.url" alt="">
                <h3>{{item.title}}</h3>
                <p>{{item.con}}</p>
            </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  import HeaderView from '@/components/headerView.vue'
  export default {
    name: 'Park',
    data(){
      return{
        bgColor:'',
        ulData:[
             {url:require('../assets/images/park/ys1.png'),title:'行业专属定制',con:'针对钢铁物流园区，行业更契合'},
             {url:require('../assets/images/park/ys2.png'),title:'园企共生',con:'园区管理与企业管理互联'},
             {url:require('../assets/images/park/ys3.png'),title:'数据共管',con:'园区管理数据开放给入驻企业'},
             {url:require('../assets/images/park/ys4.png'),title:'先进技术应用',con:'大数据、人工智能、云计算、物联网'},
             {url:require('../assets/images/park/ys5.png'),title:'环保治理',con:'园区规划并建设清洁能源与环境处理设施'},
             {url:require('../assets/images/park/ys6.png'),title:'公共仓',con:'建设公共仓，降低企业仓储成本'}
        ],
      }
    },
    components: {
      HeaderView
    },
    created(){
    },
    mounted(){
      new this.$wow.WOW().init({//新建实列
        boxClass: 'wow',//class名字
        // animateClass: 'animate__animated',//animateclass动画库类名,和当前animate动画库决定
        offset: 0,//到元素距离触发动画（当默认为0）
        mobile: true,//在移动设备上触发动画（默认为true）
        live: true//对异步加载的内容进行操作（默认为true）
      })
    },
    methods:{
      
    }
  }
  </script>
  <style scoped lang="less">
  .container{
    // 出现滚动条时，背景图铺完
    min-width: max-content;
    min-width: -moz-max-content;
    .banner{
      width:1920px;
      height:700px;
      position: relative;
      background: url('../assets/images/park/banner.jpg')no-repeat;
      background-size:100% 100%;
      .banner_text{
        width:1900px;
        margin:0 auto;
        padding-top:250px;
        text-align:center;
        color: #FEFEFE;
        box-sizing: border-box;
        animation: banner-animation 1s ease-in-out;
      }
      h1{
        height: 40px;
        font-size: 60px;
        margin-bottom:45px;
        font-family: SourceHanSansSC-Medium, SourceHanSansSC;
        font-weight: 500;
      }
      p{
        height:72px;
        line-height:72px;
        font-size: 35px;
        margin-bottom:25px;
      }
      .btn{
        width: 360px;
        height: 60px;
        line-height:60px;
        background: #0057FF;
        border-radius: 41px 41px 41px 41px;
        opacity: 1;
        font-size: 28px;
        font-family: SourceHanSansSC-Bold, SourceHanSansSC;
        font-weight: bold;
        color: #FFFFFF;
        margin:0 auto;
      }
    }
    .main_title{
        font-size: 34px;
        font-family: SourceHanSansSC-Medium, SourceHanSansSC;
        font-weight: 500;
        color: #333333;
        text-align:center;
    }
    .main{
        width: 1920px;
        height: 454px;
        background: #ffffff;
        opacity: 1;
        padding-top:60px;
        box-sizing: border-box;
        .main_box{
            width:1200px;
            margin:0 auto;
            padding-top:50px;
            display:flex;
            justify-content: space-between;
            .main_left{
                width:520px;
                padding-top:10px;
                h1{
                    display:flex;
                    align-items:center;
                    span{
                        color:#333333;
                        font-size:24px;
                        padding-left:12px;
                    }
                }
                p{
                    font-size: 14px;
                    font-family: SourceHanSansSC-Medium, SourceHanSansSC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 26px;
                    margin-top:30px;
                }
            }
        }
    }
    .main1{
        width: 1920px;
        height: 880px;
        background: #F9F9F9;
        opacity: 1;
        padding-top:85px;
        box-sizing: border-box;
        ul{
            width:1200px;
            margin:0 auto;
            margin-top:70px;
            display:flex;
            justify-content: space-between;
            flex-wrap:wrap;
            li{
                width: 350px;
                box-sizing: border-box;
                margin-bottom:85px;
                text-align:center;
                img{
                  margin-bottom:-10px;
                }
                h3{
                    color:#000000;
                    font-size:18px;
                    margin-bottom:10px;
                    font-weight:bold;
                }
                p{
                  font-size: 16px;
                  font-family: SourceHanSansSC-Regular, SourceHanSansSC;
                  font-weight: 400;
                  color: #656565;
                  line-height:28px;
                }
            }
        }
    }
  }
  </style>
  