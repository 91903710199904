<template>
    <div>
        <div class="head">
            <HeaderView :bgColor="bgColor"></HeaderView>
          </div>
        <div class="banner">
            <div class="banner_text">
                <h1>信任互联 数智互联 价值互联</h1>
                <p>携手伙伴共建数字化协作通路，助力产业数字化创新升级</p>
            </div>
        </div>
            <!-- 公司简介 -->
            <div class="auto_box">
            <div class="introduce wow animate__fadeInUp" data-wow-duration="2s">
                <div class="introduce_left">
                    <h1>
                        <span>{{companyIntroduce.title}}</span>
                        <img src="../assets/images/aboutUs/line.png" alt="">
                    </h1>
                    <p>{{companyIntroduce.value}}</p>
                </div>
                <div class="introduce_right">
                    <img src="../assets/images/aboutUs/about.png" alt="">
                </div>
            </div>
            <!-- <div class="scale">
            <ul class="wow animate__fadeInUp" data-wow-duration="2s">
                <li>
                    <h1><span>600</span>亿</h1>
                    <p>营业额突破</p>
                </li>
                <li>
                    <h1><span>600</span>家</h1>
                    <p>运营产业园</p>
                </li>
                <li>
                    <h1><span>全国</span></h1>
                    <p>产量覆盖</p>
                </li>
            </ul> 
            </div> -->
            <!-- 发展历程 -->
            <div class="course wow animate__fadeInUp" data-wow-duration="2s">
                <div class="module_title">
                        发展历程
                        <div class="line"></div>
                </div>
                <div class="course_content">
                    <div class="course_top">
                    <h1>{{history[selIndex].title}}</h1>
                        <ul class="step_content">
                            <li v-for="item in history[selIndex].content_json" :key="item">{{item}}</li>
                        </ul>  
                    </div>
                    <ul class="step">
                        <img @click='courseprev' src="../assets/images/aboutUs/left.png" alt="" class="left">
                        <li v-for="(item,index) in history" :key="index" :class="{'active': index == selIndex}" @click="selCourse(index)">
                            {{item.title}}
                            <div v-if="index == selIndex" class="sanjiao"></div>
                        </li>
                        <img @click='coursenext' src="../assets/images/aboutUs/right.png" alt="" class="left">
                    </ul>
                </div>
            </div>
            <!-- 企业荣誉 -->
            <div class="honor wow animate__fadeInUp" data-wow-duration="2s">
                <div class="module_title">
                        企业荣誉
                        <div class="line"></div>
                </div>
                <div class="rongyu">
                    <img src="../assets/images/aboutUs/rongyu.png" alt="">
                </div>
                <div class="honor_content">
                    <ul class="honor_step">
                        <img @click='honorprev' class="honorprev left" src="../assets/images/aboutUs/left.png" alt="">
                        <li v-for="(item,index) in honorBox" :key="index" :class="{'active': index == selIndex1}" @click="selCourse1(index)">
                            <dl>
                                <dt>{{item.title}}</dt>
                                <dd v-for="(Iitem,Iindex) in item.content_json" :key="Iindex">{{Iitem}}</dd>
                            </dl>
                            <div v-if="index == selIndex1" class="sanjiao"></div>
                        </li>
                        <img @click='honornext' class="honornext left" src="../assets/images/aboutUs/right.png" alt="">
                    </ul>
                </div>
            </div>
            <!-- 核心团队 -->
            <div class="module_title wow animate__fadeInUp" data-wow-duration="2s">
                核心团队
                <div class="line"></div>
            </div>
            <div class="team_box wow animate__fadeInUp" data-wow-duration="2s">
                <div class="name">{{selTeam.name}}</div>
                <img class='phone' :src="baseUrl + selTeam.image" alt=""/>
                <div class="team_content">
                    <div class="left">
                        <h1>{{selTeam.name}}<span>{{selTeam.title}}</span></h1>
                        <p>{{selTeam.info}}</p>
                    </div>
                    <div class="right">
                        <el-carousel :interval="8000" type="card" height="200px" arrow="always" @change="change">
                            <el-carousel-item v-for="(item,index) in team" :key="index">
                                <div class="imgdiv">
                                    <img :src="baseUrl + item.small_image" alt="" style="width:80px;height:80px;margin:0 auto;">
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>
            <div class="module_title wow animate__fadeInUp" data-wow-duration="2s">
                组织架构
                <div class="line"></div>
            </div>
            <div class="framework wow animate__fadeInUp" data-wow-duration="2s">
                <img src="../assets/images/aboutUs/jiagou.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
import { getAbout } from '@/request/api'
import { baseUrl } from '@/request/baseUrl'
import HeaderView from '@/components/headerView.vue'
export default {
    name:'companyProfile',
    data(){
        return{
            bgColor:'rgba(255,255,255,0)',
            companyIntroduce:'', // 公司简介
            selIndex:0, // 历程所选
            selIndex1:0, // 荣誉所选
            history:[
                {
                    title:'2023年',
                    content_json:['入选中国产业互联网最具发展潜力企业、产业发展突出贡献单位','成立郑州六六仓供应链管理有限公司']
                },
                {
                    title:'2022年',
                    content_json:['入选中国产业互联网最具发展潜力企业、产业发展突出贡献单位','成立郑州六六仓供应链管理有限公司']
                },
                {
                    title:'2021年',
                    content_json:['入选中国产业互联网最具发展潜力企业、产业发展突出贡献单位','成立郑州六六仓供应链管理有限公司']
                },
                {
                    title:'2020年',
                    content_json:['入选中国产业互联网最具发展潜力企业、产业发展突出贡献单位','成立郑州六六仓供应链管理有限公司']
                },
                {
                    title:'2019年',
                    content_json:['入选中国产业互联网最具发展潜力企业、产业发展突出贡献单位','成立郑州六六仓供应链管理有限公司']
                },
                {
                    title:'2018年',
                    content_json:['入选中国产业互联网最具发展潜力企业、产业发展突出贡献单位','成立郑州六六仓供应链管理有限公司']
                },
                {
                    title:'2017年',
                    content_json:['入选中国产业互联网最具发展潜力企业、产业发展突出贡献单位','成立郑州六六仓供应链管理有限公司']
                },
                {
                    title:'2016年',
                    content_json:['入选中国产业互联网最具发展潜力企业、产业发展突出贡献单位','成立郑州六六仓供应链管理有限公司']
                },
                {
                    title:'2015年',
                    content_json:['入选中国产业互联网最具发展潜力企业、产业发展突出贡献单位','成立郑州六六仓供应链管理有限公司']
                },
                {
                    title:'2014年',
                    content_json:['入选中国产业互联网最具发展潜力企业、产业发展突出贡献单位','成立郑州六六仓供应链管理有限公司']
                },

            ],
            honor:[], // 企业荣誉
            honorBox:[], // 企业荣誉
            step:0, // 第几组
            page:0, // 总共多少组
            team:[
                {name:'张丽',duties:'总经理/营销',introduce:'工商管理本科学历,金融硕士合伙人,现任淘钢网集团营销副总,创建淘钢网标准化经营管理体系,对钢铁行业深入研究,16年钢铁从业经验,带团队从千万做到了50亿营收,钢铁产业互联网平台专家。'},
                {name:'张丽',duties:'总经理/营销',introduce:'工商管理本科学历,金融硕士合伙人,现任淘钢网集团营销副总,创建淘钢网标准化经营管理体系,对钢铁行业深入研究,16年钢铁从业经验,带团队从千万做到了50亿营收,钢铁产业互联网平台专家。'},
                {name:'张丽',duties:'总经理/营销',introduce:'工商管理本科学历,金融硕士合伙人,现任淘钢网集团营销副总,创建淘钢网标准化经营管理体系,对钢铁行业深入研究,16年钢铁从业经验,带团队从千万做到了50亿营收,钢铁产业互联网平台专家。'}
            ],
            selTeam:{},
        }
    },
    components: {
        HeaderView
    },
    created(){
        this.baseUrl = baseUrl
        this.getAbout()
    },
    mounted(){
        new this.$wow.WOW().init({//新建实列
        boxClass: 'wow',//class名字
        // animateClass: 'animate__animated',//animateclass动画库类名,和当前animate动画库决定
        offset: 0,//到元素距离触发动画（当默认为0）
        mobile: true,//在移动设备上触发动画（默认为true）
        live: true//对异步加载的内容进行操作（默认为true）
        })
    },
    methods:{
        courseprev(){
            if(this.selIndex <= 0){
                this.selIndex = this.history.length - 1
            }else{
                this.selIndex -= 1
            }
        },
        coursenext(){
            if(this.selIndex >= this.history.length - 1){
                this.selIndex = 0
            }else{
                this.selIndex += 1
            }
        },
        honorprev(){
            if(this.step <= 0){
                this.step  = this.page-1
            }else{
                this.step -= 1
            }
            this.honorBox = this.honor.slice(this.step*3,this.step*3+3)
            this.selIndex1 = 0
        },
        honornext(){
            if(this.step >= this.page - 1){
                this.step  = 0
            }else{
                this.step += 1
            }
            this.honorBox = this.honor.slice(this.step*3,this.step*3+3)
            this.selIndex1 = 0
        },
        selCourse1(index){
            this.selIndex1 = index
        },
        selCourse(index){
            this.selIndex = index
        },
        getAbout(){
            getAbout().then((res)=>{
                console.log(res,'企业概况信息')
                this.companyIntroduce = res.data.info
                this.history = res.data.history
                this.honor = res.data.honor
                this.page = Math.ceil(this.honor.length / 3)
                console.log(this.page)
                this.honorBox = this.honor.slice(this.step,this.step+3)
                this.team = res.data.team
                this.selTeam = this.team[0]
            })
        },
        change(e){
            this.selTeam = this.team[e]
        }
    }
}
</script>
<style scoped lang="less">
.banner{
    width:1920px;
    height:471px;
    position: relative;
    background: url('../assets/images/aboutUs/banner.png')no-repeat;
    background-size:100% 100%;
    .banner_text{
      width:1200px;
      margin:0 auto;
      padding-top:170px;
      padding-left:80px;
      text-align:left;
      color: #fff;
      animation: banner-animation 1s ease-in-out;
    }
    h1{
      height: 40px;
      font-size: 40px;
      margin-bottom:20px;
    }
    p{
      font-size: 30px;
    }
}
.framework{
    width:1200px;
    margin:0 auto 70px;
    img{
        width:100%;
    }
}
.introduce{
    width:1200px;
    margin:50px auto 70px;
    display:flex;
    justify-content: space-between;
    .introduce_left{
        width:490px;
        h1{
            display:flex;
            flex-direction: column;
            font-size:28px;
            margin-top:30px;
            margin-bottom:25px;
            img{
                width:112px;
                margin-top:15px;
            }
        }
        p{
            line-height:32px;
            font-size:14px;
        }
    }
}
.scale{
    height: 184px;
    padding:50px 0;
    box-sizing:border-box;
    background: #F9F9F9;
    ul{
        width:1200px;
        margin:0 auto;
        display:flex;
        padding:0 50px;
        box-sizing:border-box;
        li{
            width:400px;
            height:80px;
            text-align:center;
            border-right:1px solid #E9E9E9;
            font-size:14px;
            color:#333333;
            &:nth-of-type(3){
                border-right:0;
            }
            h1{
                color:#1279BD;
                span{
                    font-size:52px;
                    font-weight:normal;
                }
            }
        }
    }
}
// 发展历程
.course{
    height:460px;
    background:url("../assets/images/aboutUs/bg.png")no-repeat;
    background-size:100% 100%;
    .course_content{
        width:1200px;
        margin:0px auto;
        display:flex;
        flex-direction: column;
        align-items:center;
        padding:50px 50px 0;
        .course_top{
            display:flex;
            margin-bottom:60px;
        }
        h1{
            width:400px;
            font-size:100px;
            color:#000000;
            width:500px;
            text-align:center;
        }
        .step_content{
            width:600px;
            padding-top:20px;
            li{
                list-style:disc;
                margin-left:50px;
                line-height:30px;
                &::marker{
                    color:#E73921 ;
                }
            }
        }
        .step{
            display:flex;
            align-items:center;
            li{
                border-top: 1px solid #E9E9E9;
                padding:20px;
                color:#333333;
                position:relative;
                font-size:12px;
                top:30px;
                .sanjiao{
                    position:absolute;
                    width: 0px;
                    height: 0px;
                    border: 8px solid transparent;
                    border-top-color: #E73921; 
                    top:0;
                    left:30px;
                }
            }
            .active{
                color:#E73921;
            }
            img{
                cursor: pointer;
            }
        }
    }
}
// 荣誉
.honor{
    .honor_content{
        width:1200px;
        margin:0px auto;
        display:flex;
        flex-direction: column;
        align-items:center;
        padding:50px 50px 0;
        .honor_top{
            display:flex;
            margin-bottom:60px;
        }
        h1{
            width:400px;
            font-size:100px;
            color:#000000;
            width:500px;
            text-align:center;
        }
        .step_content{
            width:600px;
            padding-top:20px;
            li{
                list-style:disc;
                margin-left:50px;
                line-height:30px;
                &::marker{
                    color:#E73921 ;
                }
            }
        }
        .honor_step{
            width:100%;
            display:flex;
            border-top:1px solid #E9E9E9;
            position:relative;
            top:30px;
            li{
                width:33.3%;
                padding:20px;
                color:#333333;
                position:relative;
                font-size:12px;
                margin:0 20px;
                padding-left:50px;
                .sanjiao{
                    position:absolute;
                    width: 0px;
                    height: 0px;
                    border: 8px solid transparent;
                    border-top-color: #E73921; 
                    top:0;
                    left:55px;
                }
                dl{
                    dd{
                        line-height:20px;
                        margin:10px 0;
                    }
                }
            }
            :hover{
                dl{
                    dt{
                        color:#E73921;
                    }
                }
            }
            .active{
                dl{
                    dt{
                        color:#E73921;
                    }
                }
            }
            img{
                cursor: pointer;
            }
        }
    }
    .rongyu{
        text-align:center;
    }
    .honorprev{
        width:47px;
        height:47px;
        position:relative;
        top:-22px;
        left:-20px;
    }
    .honornext{
        width:47px;
        height:47px;
        position:absolute;
        top:-22px;
        right:-32px;
    }
}
// 核心团队
.team_box{
    width:1200px;
    margin:0 auto;
    position:relative;
    .name{
        text-align:center;
        position:relative;
        font-size:248px;
        color:#F4F4F4;
        line-height:430px;
        padding-left:240px;
    }
    .phone{
        position:absolute;
        bottom:193px;
        left:40px;
    }
    .team_content{
        width:100%;
        height:194px;
        .left{
            width:700px;
            height:100%;
            background:#1179BD;
            float:left;
            padding:40px;
            box-sizing:border-box;
            color:#ffffff;
            h1{
                font-size:24px;
                padding-bottom:10px;
                span{
                    font-size:14px;
                    margin-left:15px;
                }
            }
            p{
                line-height:28px;
            }
        }
        .right{
            width:500px;
            height:194px;
            float:left;
            background:#F5F5F5;
        }
    }
}
/deep/.el-carousel__item{
    display:flex;
    align-items:center;
    justify-content: center!important;
    .imgdiv{
        width:80px;
        height:80px;
        border-radius:50%;
        overflow:hidden;
    }
    img{
        width:100px;
        position:relative;
        
    }
}
/deep/.el-carousel__item.is-active .imgdiv{
    border:2px solid #0B619C;
}   
/deep/.el-carousel__mask{
    background:none;
}
/deep/.el-carousel__arrow{
    background:#fff;
    color:#333;
    position:absolute;
}
/deep/.el-carousel__arrow--left{
    left:380px;
    top:35px;
}
/deep/.el-carousel__arrow--right{
    left:440px;
    top:35px;
}
/deep/.el-carousel__indicators{
    display:none;
}
</style>